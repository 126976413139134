import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useState } from "react"
import Layout from "../../components/_App/layout"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"


const parent = [
  {
    name: "Tools",
    link: "tools"
  }
]

function JsonToCSV() {
  const [json, setJson] = useState('')

  function downloadJSONAsCSV() {
    let csvData = jsonToCsv()
    let blob = new Blob([csvData], { type: "text/csv" })
    let url = window.URL.createObjectURL(blob)
    let a = document.createElement("a")
    a.href = url
    a.download = "data.csv"
    document.body.appendChild(a)
    a.click()
  }

  function flattenJSON(jsonObj, parentKey = "", separator = "_") {
    let result = {};

    for (let key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        let newKey = parentKey ? parentKey + separator + key : key;

        if (Array.isArray(jsonObj[key])) {
          for (let i = 0; i < jsonObj[key].length; i++) {
            Object.assign(
              result,
              flattenJSON(jsonObj[key][i], newKey + separator + i, separator)
            );
          }
        } else if (typeof jsonObj[key] === "object") {
          Object.assign(result, flattenJSON(jsonObj[key], newKey, separator));
        } else {
          result[newKey] = jsonObj[key];
        }
      }
    }

    return result;
  }

  function jsonToCsv() {
    const jsonData = flattenJSON(JSON.parse(json))
    let csv = ""
    let headers = Object.keys(jsonData)
    csv += headers.join(",") + "\n"

    const rows = [jsonData]
    rows.forEach(function(row) {
      let data = headers.map((header) => JSON.stringify(row[header])).join(",")
      csv += data + "\n"
    })
    return [csv]
  }

  return (
    <Layout>
      <Seo title="Tools" />
      <Navbar />
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>JSON to CSV</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle="JSON to CSV" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <div className="file_input_container">
                  <textarea name="" value={json} onChange={(e) => setJson(e.target.value)}
                            className={convertStyled.text_field} id="" cols="30" rows="10" placeholder='Enter or paste the copied JSON array'/>
                </div>
                <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary mt-3`}
                        onClick={downloadJSONAsCSV} disabled={!json}>Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />

      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default JsonToCSV
